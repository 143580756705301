<template>
  <div class="one-column-width">
    <!-- show action result -->
    <v-snackbar v-model="snackbar" absolute top right color="success">
      <span>{{ snackBarText }}</span>
      <v-icon dark>mdi-checkbox-marked-circle</v-icon>
    </v-snackbar>

    <v-snackbar v-model="errorMessage" absolute top right color="danger">
      <span>Something went RONG, Please Try Again!</span>
      <v-icon dark>mdi-checkbox-marked-circle</v-icon>
    </v-snackbar>

    <!-- Confirm Delete Item -->

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="380px">
        <v-card>
          <v-card-title>
            <span class="headline">Do you really want to DELETE this item?</span>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-submit-button" text @click="dialog = false">No</v-btn>
            <v-btn class="custom-cancel-button" text @click="deleteItem"
              ><b-spinner small type="grow" v-if="deleting"></b-spinner>Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- form start -->
    <v-card class="add-form-title" v-if="showFormData">
      <v-card-title> Add New Disciplines </v-card-title>
      <div>
        <v-form ref="form" @submit.prevent="submit" class="custom-form">
          <v-container fluid>
            <v-col cols="12" sm="8">
              <v-row v-for="(item, index) in form" :key="index">
                <v-text-field
                  v-model="form[index]"
                  :rules="rules.level"
                  color="purple name-2"
                  label="Discipline Name"
                  required
                ></v-text-field>
                <template v-if="!editId">
                  <v-btn
                    class="custom-add-new-record-button"
                    type="button"
                    @click="addNewRow()"
                    >+</v-btn
                  >
                  <v-btn
                    v-if="index > 0"
                    class="custom-delete-new-record-button"
                    type="button"
                    @click="deleteRow(index)"
                    >-</v-btn
                  >
                </template>
              </v-row>
            </v-col>
          </v-container>
          <v-card-actions>
            <v-btn
              :disabled="$v.$invalid || submitting"
              class="custom-submit-button"
              type="submit"
              ><b-spinner small type="grow" v-if="submitting"></b-spinner>
              <span v-if="!editId">Submit</span>
              <span v-if="editId">Update</span>
            </v-btn>
            <v-btn text @click="cancelForm" class="custom-cancel-button">Cancel</v-btn>
          </v-card-actions>
        </v-form>
      </div>
    </v-card>
    <!-- form end -->

    <!-- table start -->
    <v-card>
      <v-card-title>
        Disciplines
        <div class="my-2 ml-2">
          <v-btn
            class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
            fab
            small
            @click="showForm"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search Disciplines"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="tableData" :search="search" loading>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
            fab
            small
            @click="showEditForm(item)"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </v-btn>
          &nbsp;
          <v-btn
            class="btn btn-sm btn-danger font-weight-bold py-2 px-3 px-xxl-5 my-1"
            fab
            small
            @click="askUserAboutDelete(item)"
          >
            <v-icon dark>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- table end -->
  </div>
</template>

<script>
import DisciplineService from "@/MainServices/DisciplineService.js";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      form: [""],
      editId: null,
      deleteId: null,
      dialog: false,
      rules: {
        discipline: [(val) => (val || "").length > 0 || "Discipline Name is required"],
      },
      snackbar: false,
      snackBarText: null,
      submitting: false,
      deleting: false,
      errorMessage: false,
      search: "",
      showFormData: false,
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "discipline",
          width: "45%",
        },
        { text: "Slug", align: "left", sortable: false, value: "slug", width: "45%" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      tableData: [],
    };
  },
  validations: {
    form: { required, $each: { minLength: minLength(1), required } },
  },
  components: {},

  computed: {},

  methods: {
    getInititalData() {
      DisciplineService.getDisciplines()
        .then((res) => {
          this.tableData = res.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // main methods
    showForm() {
      this.editId = null;
      this.showFormData = true;
      this.resetForm();
    },
    showEditForm(item) {
      this.form = [item.discipline];
      this.editId = item.id;
      this.showFormData = true;
    },
    cancelForm() {
      this.resetForm();

      this.showFormData = false;
    },
    resetForm() {
      this.form = [""];
      this.editId = null;
      setTimeout(() => {
        this.$refs.form.reset();
      }, 100);
    },
    addNewRow() {
      this.form.push("");
    },
    deleteRow(index) {
      this.form.splice(index, 1);
    },
    submit() {
      this.submitting = true;
      if (this.editId) {
        DisciplineService.editDiscipline(this.form, this.editId)
          .then((res) => {
            this.resetForm();
            this.getInititalData();
            this.submitting = false;
            this.snackBarText = "Discipline Updated successful!";
            this.snackbar = true;
          })
          .catch((error) => {
            this.errorMessage = true;
          });
      } else {
        DisciplineService.addNewDiscipline(this.form)
          .then((res) => {
            this.resetForm();
            this.getInititalData();
            this.submitting = false;
            this.snackBarText = "Discipline Added successful!";
            this.snackbar = true;
          })
          .catch((error) => {
            this.errorMessage = true;
          });
      }
    },
    askUserAboutDelete(item) {
      this.deleteId = item.id;
      this.dialog = true;
    },
    deleteItem() {
      this.deleting = true;
      DisciplineService.deleveDiscipline(this.deleteId)
        .then((res) => {
          this.editId = null;
          this.deleting = false;
          this.dialog = false;
          this.resetForm();
          this.getInititalData();
          this.snackBarText = "Discipline Deleted successful!";
          this.snackbar = true;
        })
        .catch((error) => {
          this.errorMessage = true;
          this.dialog = false;
        });
    },
  },
  mounted() {
    this.getInititalData();
  },
};
</script>
