import ApiInstance from './ApiInstance.js'

export default {
  getDisciplines() {
    return ApiInstance({ requiresAuth: true }).get('/disciplines');
  },
  addNewDiscipline(Data) {
    return ApiInstance({ requiresAuth: true }).post('/disciplines', {discipline: Data});
  },
  editDiscipline(Data, id)
  {
    return ApiInstance({ requiresAuth: true }).put(`/disciplines/${id}`, {discipline: Data[0]});
  },
  deleveDiscipline(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/disciplines/${id}`);
  },
  getInstituteDisciplines() {
    return ApiInstance({ requiresAuth: true }).get('/institute/disciplines');
  },
  addNewInstituteDisciplines(Data) {
    return ApiInstance({ requiresAuth: true }).post('/institute/disciplines', {disciplines: Data});
  },
  deleteInstituteDiscipline(id)
  {
    return ApiInstance({ requiresAuth: true }).delete(`/institute/disciplines/${id}`);
  }
}
